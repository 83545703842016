<template>
  <div>
    <div class="pa-4 pt-0 emb-card mb-6">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Search by email, id, firstname, lastname"
            single-line
            hide-details
            append-icon="mdi-magnify"
            @click:append="search_user"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="emb-card pa-4">
      <v-data-table
        :headers="user_data_header"
        :items="user_data"
        class="elevation-1"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="white--text">
            <v-btn fab x-small @click="diplayUser(item)">
              <v-icon color="darken-2">mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.firstname="{ item }">
          <div>
            {{ item.firstname }}
          </div>
          <div v-if="item.deleted" class="red white--text pa-2">
            DELETED USER
          </div>
          <div v-if="item.banned" class="red white--text pa-2">
            BANNED USER
          </div>          
        </template>
      </v-data-table>
    </div>
    <div class="d-flex align-center py-6">
      <div>
        <v-btn @click="fetch_user_count()"
          >Hey Pat, combien de users?
          {{ user_count > 0 ? user_count : "" }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  where,
  query,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";
export default {
  data() {
    return {
      search: "",
      user_count: 0,
      user_data: [],
      user_data_header: [
        {
          text: "Firstname",
          sortable: false,
          value: "firstname",
        },
        {
          text: "Lastname",
          sortable: false,
          value: "lastname",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  methods: {
    capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    },  
    search_user() {
      this.search = this.search.trim();
      this.user_data = [];
      const queryRef = query(
        collection(getFirestore(), "users"),
        where(
          "search_words",
          "array-contains-any",
          this.search.toLowerCase().split(" ")
        )
      );
      getDocs(queryRef)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const tempData = doc.data();
            tempData["id"] = doc.id;
            this.user_data.push(tempData);
          });

          this.user_data.sort((a, b) => {
            // Compare first names
            a.firstname = this.capitalizeFirstLetter(a.firstname);
            b.firstname = this.capitalizeFirstLetter(b.firstname);
            a.lastname = this.capitalizeFirstLetter(a.lastname);
            b.lastname = this.capitalizeFirstLetter(b.lastname);

            const firstNameComparison = a.firstname.localeCompare(b.firstname);

            // If first names are the same, compare last names
            if (firstNameComparison === 0) {
              return a.lastname.localeCompare(b.lastname);
            }

            return firstNameComparison;
          });
        })
        .catch((error) => {
          if (error.code !== "") {
            this.snackbar = true;
            console.log("error: ", error);
            this.errorCode = error.code;
          }
        });
    },
    diplayUser(item) {
      this.$router.push("/admin-panel/customer-account/" + item.id);
    },
    fetch_user_count() {
      const coll = collection(getFirestore(), "users");
      getCountFromServer(coll).then((snapshot) => {
        this.user_count = snapshot.data().count;
      });
    },
  },
};
</script>